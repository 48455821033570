export const vueAppsConfig = [
  {
    selector: '.vue-app-business-account-report',
    app: () => import('~/vue-apps/dashboard/business-account-report/index.vue'),
  },
  {
    selector: '.vue-app-customer-dashboard-tabs',
    app: () => import('~/vue-apps/dashboard/local/customer-dashboard-tabs/index.vue'),
  },
  {
    selector: '.vue-app-top-data-usage',
    app: () => import('~/vue-apps/dashboard/top-data-usage/index.vue'),
  },
  {
    selector: '.vue-app-change-business-account-id',
    app: () => import('~/vue-apps/devices/edit/change-business-account-id'),
  },
  {
    selector: '.vue-app-device-employee-management',
    app: () => import('~/vue-apps/employee-management/device-employee-management.vue'),
  },
  {
    selector: '.vue-app-device-employee-field',
    app: () => import('~/vue-apps/employee-management/device-employee-field.vue'),
  },
  { selector: '.vue-app-notes', app: () => import('~/vue-apps/devices/show/notes') },
  {
    selector: '.vue-app-mdm-location-history',
    app: () => import('~/vue-apps/devices/show/mdm-location-history'),
  },
  {
    selector: '.vue-app-employee-device-page',
    app: () => import('~/vue-apps/devices/show/employee-device-page-app'),
  },
  {
    selector: '.vue-app-carrier-support',
    app: () => import('~/vue-apps/devices/show/carrier-support'),
  },
  {
    selector: '.vue-app-device-future-changes',
    app: () => import('~/vue-apps/devices/show/future-changes'),
  },
  {
    selector: '.vue-app-device-sms-messages',
    app: () => import('~/vue-apps/devices/show/tabs/sms_messages/index.vue'),
  },
  {
    selector: '.vue-app-device-info-employee',
    app: () => import('~/vue-apps/devices/show/device-info/employee.vue'),
  },
  {
    selector: '.vue-app-device-requests',
    app: () => import('~/vue-apps/devices/show/device-requests/index.vue'),
  },
  {
    selector: '.vue-app-similar-devices',
    app: () => import('~/vue-apps/devices/show/similar-devices/index.vue'),
  },
  {
    selector: '.vue-app-device-recent-history',
    app: () => import('~/vue-apps/devices/show/recent-history/index.vue'),
  },
  {
    selector: '.vue-app-device-tabs',
    app: () => import('~/vue-apps/devices/show/device-tabs/index.vue'),
  },
  {
    selector: '.vue-app-audit-history-tab',
    app: () => import('~/vue-apps/devices/show/tabs/audit_history'),
  },
  {
    selector: '.vue-app-device-carrier-hardware-orders-tab',
    app: () => import('~/vue-apps/devices/show/tabs/carrier_hardware_orders'),
  },
  { selector: '.vue-app-billing-tab', app: () => import('~/vue-apps/devices/show/tabs/billing') },
  { selector: '.vue-app-emails-tab', app: () => import('~/vue-apps/devices/show/tabs/emails') },
  {
    selector: '.vue-app-imei-history-tab',
    app: () => import('~/vue-apps/devices/show/tabs/imei_history'),
  },
  {
    selector: '.vue-app-usage-history-tab',
    app: () => import('~/vue-apps/devices/show/tabs/usage_history'),
  },
  {
    selector: '.vue-app-device-activity-tab',
    app: () => import('~/vue-apps/devices/show/tabs/activity'),
  },
  {
    selector: '.vue-app-add-roaming-package',
    app: () => import('~/vue-apps/devices/show/actions/add-roaming-package'),
  },
  {
    selector: '.vue-app-call-forwarding',
    app: () => import('~/vue-apps/devices/show/actions/call-forwarding'),
  },
  {
    selector: '.vue-app-change-ban-and-ac',
    app: () => import('~/vue-apps/devices/show/actions/change-ban-and-ac'),
  },
  {
    selector: '.vue-app-change-phone-number',
    app: () => import('~/vue-apps/devices/show/actions/change-phone-number'),
  },
  {
    selector: '.vue-app-change-sim-card',
    app: () => import('~/vue-apps/devices/show/actions/change-sim-card'),
  },
  {
    selector: '.vue-app-change-imei',
    app: () => import('~/vue-apps/devices/show/actions/change-imei'),
  },
  {
    selector: '.vue-app-data-usage-block',
    app: () => import('~/vue-apps/devices/show/actions/data-usage-block'),
  },
  {
    selector: '.vue-app-repair-request',
    app: () => import('~/vue-apps/devices/show/actions/repair-request'),
  },
  {
    selector: '.vue-app-reset-voicemail-password',
    app: () => import('~/vue-apps/devices/show/actions/reset-voicemail-password'),
  },
  {
    selector: '.vue-app-suspend-cancel-device',
    app: () => import('~/vue-apps/devices/show/actions/suspend-cancel-device'),
  },
  {
    selector: '.vue-app-transfer-of-responsibility',
    app: () => import('~/vue-apps/devices/show/actions/transfer-of-responsibility'),
  },
  {
    selector: '.vue-app-employee-suspend-cancel-device',
    app: () => import('~/vue-apps/devices/show/actions/suspend-cancel-device/employee'),
  },
  {
    selector: '.vue-app-change-plan-features',
    app: () => import('~/vue-apps/devices/show/actions/change-plan-features'),
  },
  {
    selector: '.vue-app-email-setup',
    app: () => import('~/vue-apps/devices/show/actions/email-setup'),
  },
  {
    selector: '.vue-app-delete-device',
    app: () => import('~/vue-apps/devices/show/actions/delete-device'),
  },
  {
    selector: '.vue-app-redeployment-available',
    app: () => import('~/vue-apps/devices/show/actions/redeployment-available'),
  },
  {
    selector: '.vue-app-suspend-cancel-device-custom-report',
    app: () => import('~/vue-apps/reports/custom-report-device-suspend-cancel.vue'),
  },
  {
    selector: '.vue-app-billing-cycle-info',
    app: () => import('~/vue-apps/dashboard/local/billing-cycle-info.vue'),
  },
  {
    selector: '.vue-app-customer-dashboard-chart',
    app: () => import('~/vue-apps/customer-dashboard/index.vue'),
  },
  { selector: '.vue-app-global-dashboard', app: () => import('~/vue-apps/dashboard/global') },
  {
    selector: '.vue-app-device-statistic',
    app: () => import('~/vue-apps/dashboard/local/device-statistic'),
  },
  {
    selector: '.vue-app-dashboard-notes',
    app: () => import('~/vue-apps/dashboard/local/dashboard-notes'),
  },
  {
    selector: '.vue-app-custom-report',
    app: () => import('~/vue-apps/reports/custom_report/index.vue'),
  },
  {
    selector: '.vue-app-orders-excel-report-generator-form',
    app: () => import('~/vue-components/daterange-downloadable-report-form'),
  },
  {
    selector: '.vue-app-signature-requests-report-generator-form',
    app: () => import('~/vue-components/daterange-downloadable-report-form'),
  },
  {
    selector: '.vue-app-order-notification',
    app: () => import('~/vue-apps/order-notification/index.vue'),
  },
  {
    selector: '#business_account_invoice_auditor_ids',
    app: () => import('~/vue-components/contact-tags-input'),
  },
  {
    selector: '.vue-app-business-account-group-index-app',
    app: () => import('~/vue-apps/business-account-groups-table/index-app'),
  },
  {
    selector: '.vue-app-business-account-invoice-auditor-ids',
    app: () => import('~/vue-components/contact-tags-input'),
  },
  {
    selector: '.vue-app-business-contact-employee-management',
    app: () => import('~/vue-apps/business-contact-employee-management'),
  },
  {
    selector: '.vue-app-business-unit-review-employee-management',
    app: () => import('~/vue-apps/business-unit-review-employee-management'),
  },
  {
    selector: '.vue-app-live-data-pooling-report',
    app: () => import('~/vue-apps/reports/live_data_pooling'),
  },
  {
    selector: '.vue-app-managerial-report-direct-cost-centers-tab',
    app: () => import('~/vue-apps/reports/secure/managerial/tabs/direct_cost_centers'),
  },
  {
    selector: '.vue-app-managerial-report-sub-managers-tab',
    app: () => import('~/vue-apps/reports/secure/managerial/tabs/sub_managers'),
  },
  {
    selector: '.vue-app-managerial-report-sender',
    app: () => import('~/vue-apps/reports/managerial/manage/report-sender'),
  },
  {
    selector: '.vue-app-managerial-report-events',
    app: () => import('~/vue-apps/reports/managerial/manage/events'),
  },
  { selector: '.vue-app-average-cost', app: () => import('~/vue-apps/reports/average_cost') },
  {
    selector: '.vue-app-suspension-candidates',
    app: () => import('~/vue-apps/reports/suspension_candidates'),
  },
  {
    selector: '.vue-app-wireline-employee-management',
    app: () => import('~/vue-apps/wireline-employee-management'),
  },
  {
    selector: '.vue-app-delegate-managerial-report',
    app: () => import('~/vue-apps/reports/managerial/delegate-report'),
  },
  {
    selector: '.vue-app-lines-of-service',
    app: () => import('~/vue-apps/lines-of-service/index.vue'),
  },
  {
    selector: '.vue-app-hw-orders',
    app: () => import('~/vue-apps/devices/show/tabs/hw_orders/index.vue'),
  },
  {
    selector: '.vue-app-helpdesk-hw-orders',
    app: () => import('~/vue-apps/hardware_orders/index.vue'),
  },
  {
    selector: '.vue-app-hw-orders-show-logs',
    app: () => import('~/vue-apps/hardware_orders/show/logs/index.vue'),
  },
  {
    selector: '.vue-app-device-usage-threshold',
    app: () => import('~/vue-apps/device-usage-threshold/index.vue'),
  },
  {
    selector: '.vue-app-orders-address-complete',
    app: () => import('~/vue-apps/orders-address-complete.vue'),
  },
  {
    selector: '.vue-app-global-notifications-app',
    app: () => import('~/vue-apps/global-notifications-app/global-notifications-app.vue'),
  },
  {
    selector: '.vue-app-bill-audit-workflows',
    app: () => import('~/vue-apps/bill-audit-workflows/index.vue'),
  },
  {
    selector: '.vue-app-carrier-contracts',
    app: () => import('~/vue-apps/settings/carrier_contracts'),
  },
  {
    selector: '.vue-app-reports-monthly-billing',
    app: () => import('~/vue-apps/reports/monthly/index.vue'),
  },
  {
    selector: '.vue-app-average-costs',
    app: () => import('~/vue-apps/reports/average_costs/index.vue'),
  },
]
